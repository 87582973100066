import React from 'react';
import { useParams } from 'react-router';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { TabConfig, getFaIcon } from '@odin-labs/components';
import { ScrollSpyTabsPages, useScrollSpyTabs } from 'components/tabs';
import { NewHeader } from 'components/header/NewHeader';
import { NewHeaderActionsProps } from 'components/header/types';
import { RouterPrompt } from 'components/routerPrompt';
import { AuthContext } from 'auth';
import { evalJsCode, useDeferredFormSubmission, useIsMounted } from 'utils';
import { Container } from 'components/container';
import { useGetJobsiteFormSubmissionQuery } from 'apollo/generated/client-operations';
import { getDateTimeMoment } from 'utils/dates';
import { FormSubmissionTabDefinition, FormSubmissionTabProps, FormSubmissionTabWithRefProps } from './types';
import { FormSubmissionEdit } from './tabs/FormSubmissionEdit';
import { FormSubmissionTitle } from './components/FormSubmissionTitle';
import { getEvalContext } from './tabs/FormSubmissionEdit.forms';
import { useDependencies } from './utils';

const CheckIcon = getFaIcon({ icon: faCheck });

export const classes = {
  container: 'odin-h-full',
  tabPage: 'odin-overflow-y-scroll odin-bg-white odin-h-full',
  headerTitleContainer: 'odin-flex odin-space-x-5 odin-items-center',
  headerTitleBadgesContainer: 'odin-space-x-2',
};

export const getTabsConfig = (tabs: FormSubmissionTabDefinition): TabConfig<FormSubmissionTabProps>[] => {
  return (
    Object.entries(tabs ?? {}).map(([name, tabDef], index) => ({
      name,
      text: typeof tabDef === 'string' ? tabDef : tabDef.text,
      badge: typeof tabDef === 'string' ? '' : tabDef.badge,
      relativePath: index === 0 ? '' : `#${name}`,
      component: FormSubmissionEdit,
    })) ?? []
  );
};

export function FormSubmissionContainer(): React.ReactElement {
  const { formType, formSubmissionId } = useParams<{ formType: string; formSubmissionId: string }>();
  const [tabsDefinition, setTabsDefinition] = React.useState<FormSubmissionTabDefinition>({});
  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission();

  const { currentUser: user } = React.useContext(AuthContext);

  const baseRoute = `/forms/${formType}/${formSubmissionId}`;
  const tabsConfig = React.useMemo(() => getTabsConfig(tabsDefinition), [tabsDefinition]);
  const { tabs, currentTab } = useScrollSpyTabs({ tabsConfig, baseRoute });

  const { data, loading, refetch } = useGetJobsiteFormSubmissionQuery({
    variables: { id: formSubmissionId },
    fetchPolicy: 'no-cache',
    skip: !formSubmissionId,
  });

  const jobsiteFormSubmission = data?.getJobsiteFormSubmission;

  const { dependencies: dependenciesExpressions, tabs: tabsExpression } =
    jobsiteFormSubmission?.jobsiteForm.form.content.edit ?? {};

  const dependencies = useDependencies(dependenciesExpressions);

  React.useEffect(() => {
    if (dependencies && tabsExpression) {
      const evalContext = getEvalContext({ dependencies, user, jobsiteFormSubmission });
      const computedTabs = evalJsCode<FormSubmissionTabDefinition>(tabsExpression, evalContext);
      setTabsDefinition(computedTabs);
    }
  }, [dependencies, tabsExpression]);

  const headerActionsProps = React.useMemo<NewHeaderActionsProps>(
    () => ({
      menuItems: [
        {
          onClick: submitForm,
          text: 'Save Changes',
          hideTextOnMobile: false,
          withSpinner: isSaving,
          icon: CheckIcon,
          disabled: !isFormDirty,
        },
      ],
      baseRoute,
      headerActions: ['back', 'print'],
    }),
    [submitForm, isFormDirty, isSaving],
  );

  const tabsPageProps = React.useMemo(
    (): FormSubmissionTabWithRefProps => ({
      loading,
      jobsiteFormSubmission,
      onIsDirtyChange: setIsFormDirty,
      setTabsDefinition,
      ref: formRef,
      deferredSubmission,
      refetchJobsiteFormSubmission: refetch,
      isSaving,
      setIsSaving: (value: boolean): void => {
        if (isMounted()) {
          setIsSaving(value);
          if (!value) setIsFormDirty(false);
        }
      },
    }),
    [
      loading,
      jobsiteFormSubmission,
      deferredSubmission,
      setIsFormDirty,
      formRef,
      refetch,
      isSaving,
      setIsSaving,
      isMounted,
    ],
  );

  const { jobsiteForm, startAt, timeZone } = jobsiteFormSubmission ?? {};
  const { jobsite } = jobsiteForm ?? {};
  const { contractor } = jobsiteFormSubmission?.jobsiteContractors?.edges[0]?.node.jobsiteContractor ?? {};

  return (
    <Container className={classes.container}>
      <RouterPrompt when={isFormDirty} onConfirm={submitForm} />
      <NewHeader
        title={
          <FormSubmissionTitle
            date={getDateTimeMoment({ date: startAt, timeZone, isUTC: true })}
            jobsiteName={jobsite?.name}
            contractorName={contractor?.organization.name}
          />
        }
        tabsProps={tabsConfig.length ? { tabs, currentTab } : null}
        actionsProps={headerActionsProps}
      />
      {!!tabsConfig.length && (
        <ScrollSpyTabsPages
          className={classes.tabPage}
          baseRoute={baseRoute}
          tabs={tabs}
          componentProps={tabsPageProps}
        />
      )}
    </Container>
  );
}
