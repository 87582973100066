import React from 'react';
import cn from 'classnames';
import { JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/print/types';
import { getPrettyFormattedUtcTime } from 'utils/dates';

const classes = {
  title: cn('odin-text-gray-900 odin-text-lg odin-leading-6 odin-font-medium odin-mr-9'),
  container: cn('sm:odin-grid sm:odin-grid-cols-3 odin-gap-x-6'),
  detailLabel: cn('odin-text-sm odin-leading-5 odin-font-normal odin-text-gray-900'),
  detailValue: cn('odin-text-3xl odin-leading-10 odin-font-bold odin-text-odin-black odin-mt-3'),
  detailValueSkeleton: cn('odin-animate-pulse odin-w-4/6 odin-bg-gray-300 odin-h-8 odin-rounded-md -odin-ml-1'),
  icon: (faClasses: string): string => cn(faClasses, 'odin-text-odin-primary odin-w-6 odin-text-2xl'),
};

export type FormSubmissionReportWorkforceStatsItemProps = {
  label: string;
  value?: string | number;
  iconClassName: string;
  loading: boolean;
};

function FormSubmissionReportWorkforceStatsItem(
  props: FormSubmissionReportWorkforceStatsItemProps,
): React.ReactElement {
  const { label, value, iconClassName, loading } = props;
  const valueContent = loading ? <div className={classes.detailValueSkeleton} /> : value?.toLocaleString('en-US');
  return (
    <div>
      <i className={iconClassName} />
      <div className={classes.detailValue}>{valueContent}</div>
      <div className={classes.detailLabel}>{label}</div>
    </div>
  );
}

export type FormSubmissionReportWorkforceStatsProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  loading: boolean;
};

export function FormSubmissionReportWorkforceStats(props: FormSubmissionReportWorkforceStatsProps): React.ReactElement {
  const { jobsiteFormSubmission, loading } = props;
  const workersCount = jobsiteFormSubmission?.extraData?.workersCount ?? jobsiteFormSubmission?.jobsiteWorkers.count;
  const { startAt, isStartTimeSpecified, endAt, timeZone } = jobsiteFormSubmission ?? {};

  const startTime = getPrettyFormattedUtcTime(startAt, timeZone);
  const startTimeText = startTime === '12:00 AM' && !isStartTimeSpecified ? '' : startTime;
  const endTime = getPrettyFormattedUtcTime(endAt, timeZone);

  return (
    <div>
      <div className="odin-mb-6">
        <span className={classes.title}>Workforce Stats</span>
      </div>
      <div className={classes.container}>
        <FormSubmissionReportWorkforceStatsItem
          loading={loading}
          label="Manpower"
          value={workersCount}
          iconClassName={classes.icon('fal fa-construction')}
        />
        <FormSubmissionReportWorkforceStatsItem
          loading={loading}
          label="Start Time"
          value={startTimeText}
          iconClassName={classes.icon('fal fa-arrow-to-right')}
        />
        <FormSubmissionReportWorkforceStatsItem
          loading={loading}
          label="End Time"
          value={endTime}
          iconClassName={classes.icon('fal fa-arrow-from-right')}
        />
      </div>
    </div>
  );
}
