import React from 'react';
import cn from 'classnames';
import moment from 'moment';

export const classes = {
  titleSkeleton: cn(
    'odin-text-transparent odin-text-2xl',
    'odin-animate-pulse odin-bg-gray-300 odin-rounded-md -odin-ml-1',
  ),
};

export function FormSubmissionTitle({
  date,
  jobsiteName,
  contractorName,
}: {
  date: moment.Moment;
  jobsiteName?: string;
  contractorName?: string;
}): React.ReactElement {
  const dateText = date?.format('MMM DD, YYYY');
  return (
    <div className="odin-flex odin-gap-x-8">
      <div className="odin-flex odin-flex-col odin-justify-around odin-gap-y-2">
        <h1 className="odin-text-xl odin-font-bold odin-text-odin-black odin-mb-0 sm:odin-text-2xl sm:odin-truncate">
          {dateText ?? <span className={classes.titleSkeleton}>Loading Log Data</span>}
        </h1>
        <span>
          {jobsiteName && (
            <span className="odin-text-sm">
              <span className="fal fa-briefcase odin-text-base odin-text-odin-primary odin-mr-2" />
              {jobsiteName}
            </span>
          )}
          {contractorName && (
            <span className="odin-text-sm odin-ml-4">
              <span className="fal fa-wrench odin-text-base odin-text-odin-primary odin-mr-2" />
              {contractorName}
            </span>
          )}
        </span>
      </div>
    </div>
  );
}
