import React from 'react';
import {
  JobsiteFormSubmission,
  JobsiteFormSubmissionWorker,
  JobsiteFormSubmissionWorkerContractor,
} from 'containers/jobsiteFormSubmission/print/types';
import { FormSubmissionReportContractorWorkers as ReportContractorWorkers } from './FormSubmissionReportContractorWorkers';

type ContractorWithWorkers = {
  contractor: JobsiteFormSubmissionWorkerContractor;
  workers: JobsiteFormSubmissionWorker[];
};

export type FormSubmissionReportWorkersProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  allowMultipleContractors: boolean;
  loading: boolean;
};

export function FormSubmissionReportWorkers(props: FormSubmissionReportWorkersProps): React.ReactElement {
  const { jobsiteFormSubmission, loading, allowMultipleContractors } = props;

  if (allowMultipleContractors) {
    const workersByContractor = jobsiteFormSubmission?.jobsiteWorkers.edges.reduce((result, { node: worker }) => {
      const { contractor } = worker.jobsiteWorker.contractorWorker;
      const { contractorId } = contractor;
      if (!result[contractorId]) {
        Object.assign(result, { [contractorId]: { contractor, workers: [] } });
      }
      result[contractorId].workers.push(worker);
      return result;
    }, {} as Record<string, ContractorWithWorkers>);

    return (
      <>
        {Object.values(workersByContractor).map(({ contractor, workers }) => (
          <ReportContractorWorkers
            key={contractor.contractorId}
            contractor={contractor}
            workers={workers}
            loading={loading}
          />
        ))}
      </>
    );
  }

  const workers = jobsiteFormSubmission?.jobsiteWorkers.edges.map((w) => w.node) ?? [];
  return <ReportContractorWorkers workers={workers} loading={loading} />;
}
