import React from 'react';
import cn from 'classnames';
import { JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/print/types';
import { getPrettyFormattedUtcDate } from 'utils/dates';

const classes = {
  row: cn(
    'odin-flex odin-flex-col',
    'odin-mt-5 odin-space-y-1.5',
    'sm:odin-flex-row sm:odin-justify-between sm:odin-space-x-6',
  ),
  leftContainer: cn('odin-flex odin-text-gray-900 odin-text-lg odin-leading-6 odin-font-medium'),
  rightContainer: cn(
    'odin-grid',
    'odin-grid-cols-2',
    'odin-gap-y-1.5',
    'odin-ml-11',
    'sm:odin-flex',
    'sm:odin-space-x-6',
  ),
  infoItem: cn(
    'odin-flex odin-items-center',
    'odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal',
    'odin-truncate',
  ),
  infoItemIcon: (icon: string): string =>
    cn(icon, 'odin-text-odin-primary', 'odin-text-sm', 'odin-font-normal', 'odin-mr-1.5'),
};

export type FormSubmissionReportHeaderProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
};

export function FormSubmissionReportHeader(props: FormSubmissionReportHeaderProps): React.ReactElement {
  const { jobsiteFormSubmission } = props;
  const { jobsiteForm, startAt, timeZone } = jobsiteFormSubmission ?? {};
  const { jobsite, form } = jobsiteForm ?? {};
  const { name: formName } = form ?? {};
  const { organization } = jobsiteFormSubmission?.jobsiteContractors.edges[0]?.node.jobsiteContractor.contractor ?? {};

  return (
    <div className={classes.row}>
      <div className={classes.leftContainer}>{formName}</div>
      <div className={classes.rightContainer}>
        <div className={classes.infoItem}>
          <i className={classes.infoItemIcon('fal fa-briefcase')} aria-hidden="true" />
          {jobsite?.name}
        </div>
        {organization && (
          <div className={classes.infoItem}>
            <i className={classes.infoItemIcon('fal fa-wrench')} aria-hidden="true" />
            {organization?.name}
          </div>
        )}
        <div className={classes.infoItem}>
          <i className={classes.infoItemIcon('fal fa-calendar')} aria-hidden="true" />
          {getPrettyFormattedUtcDate(startAt, timeZone)}
        </div>
      </div>
    </div>
  );
}
