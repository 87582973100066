import React from 'react';
import cn from 'classnames';
import { LoadingError } from 'components/loadingError';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Avatar } from '@odin-labs/components';
import { useGetJobsiteFormSubmissionReportQuery } from 'apollo/generated/client-operations';
import { evalJsCode, getInitialsForUser, getUserFullName } from 'utils';
import { getDateTimeMoment, getPrettyFormattedDateTime } from 'utils/dates';
import { AuthContext } from 'auth';
import { PrintFormContent } from 'containers/jobsiteFormSubmission/types';
import { useDependencies } from 'containers/jobsiteFormSubmission/utils';
import { getEvalContext } from 'containers/jobsiteFormSubmission/tabs/FormSubmissionEdit.forms';
// import {
//   FormSubmissionPrintContext,
//   FormSubmissionPrintContextState,
//   QueryStatus,
// } from 'containers/jobsiteFormSubmission/print';
import {
  FormSubmissionReportLoading as ReportLoading,
  FormSubmissionReportDocuments as ReportDocuments,
  FormSubmissionReportWorkers as ReportWorkers,
  FormSubmissionReportWorkforceStats as ReportWorkforceStats,
  FormSubmissionReportHeader as ReportHeader,
  FormSubmissionReportTitle as ReportTitle,
  FormSubmissionReportDetails as ReportDetails,
  FormSubmissionReportWeather as ReportWeather,
  FormSubmissionReportData as ReportData,
  FormSubmissionReportDataItem as ReportDataItem,
} from 'containers/jobsiteFormSubmission/print/components';
import { FormSubmissionPrintContext } from './FormSubmissionPrintContext';
import { FormSubmissionPrintContextState, QueryStatus } from './types';

export function FormSubmissionPrintContainer(): React.ReactElement {
  const { formSubmissionId } = useParams<{ formSubmissionId: string }>();
  const [queryStatus, setQueryStatus] = React.useState<Record<string, QueryStatus>>({});

  const { currentUser: user } = React.useContext(AuthContext);

  const { data, error, loading } = useGetJobsiteFormSubmissionReportQuery({
    variables: { id: formSubmissionId },
    fetchPolicy: 'no-cache',
  });

  const jobsiteFormSubmission = data?.getJobsiteFormSubmission;
  const { startAt, timeZone, jobsiteForm, objectHistory } = jobsiteFormSubmission ?? {};
  const { name: formName } = jobsiteForm?.form ?? {};

  const updateQueryStatus = (queryName: string, queryStatusItem: QueryStatus): void =>
    setQueryStatus((currentQueryStatus) => ({
      ...currentQueryStatus,
      [queryName]: queryStatusItem,
    }));

  const formSubmissionPrintContextValue = React.useMemo<FormSubmissionPrintContextState>(
    () => ({
      updateQueryStatus,
    }),
    [setQueryStatus],
  );

  const { dependencies: dependenciesExpressions, config: configExpression } =
    jobsiteFormSubmission?.jobsiteForm.form.content.print ?? {};
  const dependencies = useDependencies(dependenciesExpressions);

  const config = React.useMemo(() => {
    const evalContext =
      jobsiteFormSubmission && dependencies && getEvalContext({ dependencies, user, jobsiteFormSubmission });
    return evalContext && evalJsCode<PrintFormContent['config']>(configExpression, evalContext);
  }, [jobsiteFormSubmission, dependencies, configExpression]);

  const areQueriesLoading = loading || Object.values(queryStatus).some((qs) => qs.loading);

  if (loading || error) {
    return <LoadingError loading={loading} error={error} />;
  }

  const reportMoment = getDateTimeMoment({ date: startAt, timeZone, isUTC: true });
  const reportDateText = reportMoment.format('MMM DD, YYYY');

  const showDocuments = !!jobsiteFormSubmission?.documents.edges.length;

  const lastUpdatedAt = objectHistory.updatedAt ?? objectHistory.createdAt;
  const lastUpdatedBy = objectHistory.updatedBy ?? objectHistory.createdBy;

  const userName = getUserFullName(lastUpdatedBy);
  const userInitials = getInitialsForUser(lastUpdatedBy);
  const { profilePictureCropped } = lastUpdatedBy?.worker ?? {};

  const historySectionItems: ReportDataItem[] = [
    {
      title: 'Last Updated By',
      text: (
        <div className="odin-flex odin-gap-x-3">
          <Avatar size="xs" src={profilePictureCropped?.downloadUrl} placeholder={userInitials} />
          <span>{userName}</span>
        </div>
      ),
    },
    { title: 'Last Updated Date', text: getPrettyFormattedDateTime(lastUpdatedAt) },
  ];

  return (
    <Container>
      <Row>
        <Col xs="12" className="odin-mb-9">
          <Helmet title={`ODIN ${formName} - ${reportDateText}`} />
          <ReportLoading loading={areQueriesLoading} />
          <div className={cn('odin-flex odin-flex-col odin-gap-y-9', areQueriesLoading && 'print:odin-hidden')}>
            <FormSubmissionPrintContext.Provider value={formSubmissionPrintContextValue}>
              <ReportHeader jobsiteFormSubmission={jobsiteFormSubmission} />
              <ReportTitle jobsiteFormSubmission={jobsiteFormSubmission} />
              <ReportDetails jobsiteFormSubmission={jobsiteFormSubmission} />
              {config?.showWeatherInfo && <ReportWeather jobsiteFormSubmission={jobsiteFormSubmission} />}
              {config?.sections.map((section) => (
                <ReportData key={section.text} title={section.text} items={section.items} columns={section.columns} />
              ))}
              {config?.showWorkforceStats && (
                <ReportWorkforceStats jobsiteFormSubmission={jobsiteFormSubmission} loading={loading} />
              )}
              {config?.showWorkers && (
                <ReportWorkers
                  jobsiteFormSubmission={jobsiteFormSubmission}
                  allowMultipleContractors={config.allowMultipleContractors}
                  loading={loading}
                />
              )}
              {showDocuments && <ReportDocuments jobsiteFormSubmission={jobsiteFormSubmission} singleItemPerRow />}
              <ReportData title="History" items={historySectionItems} columns={2} />
            </FormSubmissionPrintContext.Provider>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
