import React from 'react';
import cn from 'classnames';
import { JobsiteFormSubmission } from 'containers/jobsiteFormSubmission/print/types';
import { getInitialsForUser, getUserFullName } from 'utils';
import { Avatar, getFaIcon } from '@odin-labs/components';
import { faBriefcase, faWrench } from '@fortawesome/pro-light-svg-icons';

const classes = {
  container: cn('sm:odin-grid sm:odin-grid-cols-3 odin-gap-x-5'),
  detailContainer: '',
  detailLabel: cn('odin-text-sm odin-font-medium odin-text-gray-900 odin-mt-3 odin-mb-1'),
  detailValue: cn('odin-text-sm odin-font-normal odin-break-words'),
};

export type FormSubmissionReportDetailProps = React.PropsWithChildren<{
  icon: React.ReactNode;
  label: string;
  value?: string | JSX.Element | JSX.Element[];
}>;

function FormSubmissionReportDetail(props: FormSubmissionReportDetailProps): React.ReactElement {
  const { label, value, children, icon: Icon } = props;
  return (
    <div className={classes.detailContainer}>
      {typeof Icon === 'function' ? <Icon /> : Icon}
      <div className={classes.detailLabel}>{label}</div>
      <div className={classes.detailValue}>{value ?? children}</div>
    </div>
  );
}

export type JobsiteReportDetailsProps = {
  jobsiteFormSubmission: JobsiteFormSubmission;
};

const WrenchIcon = getFaIcon({ icon: faWrench, className: cn('odin-text-odin-primary') });
const BriefcaseIcon = getFaIcon({ icon: faBriefcase, className: cn('odin-text-odin-primary') });

export function FormSubmissionReportDetails(props: JobsiteReportDetailsProps): React.ReactElement {
  const { jobsiteFormSubmission } = props;
  const { jobsiteForm, objectHistory } = jobsiteFormSubmission ?? {};
  const { jobsite } = jobsiteForm ?? {};
  const { organization } = jobsiteFormSubmission?.jobsiteContractors.edges[0]?.node.jobsiteContractor.contractor ?? {};

  const jobsiteAddress = jobsite && (
    <>
      {jobsite.addressLine1} {jobsite.addressLine2} <br />
      {[jobsite.addressCity, jobsite.addressState].filter(Boolean).join(', ')} {jobsite.addressZipCode}
    </>
  );

  const contractorAddress = organization && (
    <>
      {organization.addressLine1} {organization.addressLine2} <br />
      {[organization.addressCity, organization.addressState].filter(Boolean).join(', ')} {organization.addressZipCode}
    </>
  );

  const { createdBy } = objectHistory ?? {};
  const userName = getUserFullName(createdBy);
  const userInitials = getInitialsForUser(createdBy);
  const { phoneNumber, email } = createdBy?.identity ?? {};
  const { trade, jobTitle, profilePictureCropped } = createdBy?.worker ?? {};

  const userDetails = createdBy && (
    <>
      {[trade, jobTitle].filter(Boolean).join(', ')} <br />
      {phoneNumber} <br />
      {email}
    </>
  );
  const workerPicture = <Avatar size="xs" src={profilePictureCropped?.downloadUrl} placeholder={userInitials} />;

  return (
    <div className={classes.container}>
      <FormSubmissionReportDetail icon={BriefcaseIcon} label={jobsite?.name} value={jobsiteAddress} />
      {organization && (
        <FormSubmissionReportDetail icon={WrenchIcon} label={organization?.name} value={contractorAddress} />
      )}
      <FormSubmissionReportDetail icon={workerPicture} label={userName} value={userDetails} />
    </div>
  );
}
