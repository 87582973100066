import React from 'react';
import { DeepMap } from 'react-hook-form';
import moment, { Moment } from 'moment';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { UseInputs, TypedFormInputs, UseFormMethods, getUpdateInputValueFunction, FormInput } from 'components/form';
import {
  ChangeType,
  Contractor,
  JobsiteContractor,
  JobsiteCreateFormSubmissionInput,
  useGetJobsiteAndContractorsQuery,
  useGetJobsiteFormsQuery,
} from 'apollo/generated/client-operations';
import { AuthUser } from 'acl';
import { ensureNonUndefinedFields, evalJsCode } from 'utils';
import { getDateTime } from 'utils/dates';
import {
  AvailableJobsiteWorkerOption,
  AvailableJobsiteWorkerOptions,
  WatchedFieldConfig,
} from 'containers/jobsiteFormSubmission/types';
import { getEvalContext } from 'containers/jobsiteFormSubmission/tabs/FormSubmissionEdit.forms';
import { Form } from 'containers/forms/formsTab/types';
import {
  useWatchedFields,
  formInputsAsArray,
  useAvailableJobsiteWorkerOptions,
} from 'containers/jobsiteFormSubmission/utils';
import { AddFormSubmissionFormData, JobsiteFormOptionElement } from './types';

export type JobsiteContractorForOption = Pick<JobsiteContractor, 'id'> & {
  contractor: Pick<Contractor, 'contractorId' | 'isDefaultContractor'> & {
    organization: Pick<Contractor['organization'], 'name'>;
  };
};

export const getJobsiteContractorsOptions = (jobsiteContractors: JobsiteContractorForOption[]): SelectOptionElement[] =>
  jobsiteContractors
    ?.map(
      ({
        id: value,
        contractor: {
          organization: { name: label },
          isDefaultContractor,
        },
      }) => ({ label, value, isDefaultContractor }),
    )
    .filter(({ isDefaultContractor }) => !isDefaultContractor)
    .sort((a, b) => a.label.localeCompare(b.label));

export const useJobsiteFormsOptions = (formKey: string): JobsiteFormOptionElement[] => {
  const { data: jobsiteForms, loading } = useGetJobsiteFormsQuery({
    fetchPolicy: 'no-cache',
    skip: !formKey,
    variables: { jobsiteFormsInput: { formKey } },
  });

  return React.useMemo(() => {
    if (loading) return [];
    return jobsiteForms?.getCurrentSession.user.jobsiteForms.edges.map<JobsiteFormOptionElement>(
      ({ node: { id, jobsite } }) => ({
        value: id,
        label: jobsite.name,
        jobsiteId: jobsite.jobsiteId,
        timeZone: jobsite.timeZone,
      }),
    );
  }, [jobsiteForms]);
};

export const useJobsiteContractorsOptions = (args: { jobsiteId: string; skip: boolean }): SelectOptionElement[] => {
  const { jobsiteId, skip } = args;
  const { data: jobsiteContractors, loading } = useGetJobsiteAndContractorsQuery({
    fetchPolicy: 'no-cache',
    skip: skip || !jobsiteId,
    variables: { jobsiteId },
  });

  return React.useMemo(() => {
    if (loading) return [];
    const selectedJobsiteContractors = jobsiteContractors?.getJobsite.jobsiteContractors.edges.map(({ node }) => node);
    return getJobsiteContractorsOptions(selectedJobsiteContractors);
  }, [jobsiteContractors]);
};

export type GetFormInputsArgs = {
  user: AuthUser;
  form: Form;
  jobsiteFormsOptions: JobsiteFormOptionElement[];
  defaultValues: AddFormSubmissionFormData;
  dependencies: Record<string, unknown>;
};

export const getFormInputsHook =
  (args: GetFormInputsArgs): UseInputs<AddFormSubmissionFormData> =>
  (methods: UseFormMethods<AddFormSubmissionFormData>): FormInput<AddFormSubmissionFormData>[] => {
    const { watch, getValues, setValue } = methods;
    const { user, form, jobsiteFormsOptions, defaultValues, dependencies } = args;
    const {
      inputs: inputsExpression,
      defaultValues: defaultValuesExpression,
      watchedFields: watchedFieldsConfig,
    } = form?.content.modal ?? {};

    const watchedFields = (watchedFieldsConfig as WatchedFieldConfig<AddFormSubmissionFormData>[]).map((item) =>
      typeof item === 'string' ? item : item.field,
    );
    const watched = watch(watchedFields) as Partial<AddFormSubmissionFormData>;
    const state = { ...defaultValues, ...getValues(), ...watched };

    const selectedJobsiteOption = watch('jobsiteFormId');
    const selectedJobsiteId = selectedJobsiteOption?.jobsiteId;

    const contractorsOptions = useJobsiteContractorsOptions({
      jobsiteId: selectedJobsiteId,
      skip:
        !(inputsExpression as string)?.includes('ctx.options.contractors') &&
        !(defaultValuesExpression as string)?.includes('ctx.options.contractors'),
    });

    const { availableJobsiteWorkerOptions } = useAvailableJobsiteWorkerOptions({
      jobsiteFormSubmission: undefined,
      jobsiteId: selectedJobsiteId,
      skip:
        !(inputsExpression as string)?.includes('ctx.options.availableJobsiteWorkers') &&
        !(defaultValuesExpression as string)?.includes('ctx.options.availableJobsiteWorkers'),
    });

    useDidUpdateEffect(() => {
      setValue('jobsiteContractorId', contractorsOptions?.length === 1 ? contractorsOptions[0] : null);
    }, [contractorsOptions]);

    const evalContext = React.useMemo(() => {
      return (
        dependencies &&
        getEvalContext({
          user,
          jobsiteFormSubmission: undefined,
          dependencies,
          edit: state,
          form: methods,
          options: {
            jobsiteForms: jobsiteFormsOptions,
            contractors: contractorsOptions,
            availableJobsiteWorkers: availableJobsiteWorkerOptions,
          },
        })
      );
    }, [
      user,
      dependencies,
      JSON.stringify(state),
      methods,
      availableJobsiteWorkerOptions,
      contractorsOptions,
      jobsiteFormsOptions,
    ]);

    useWatchedFields(watchedFieldsConfig, watched, evalContext);

    return React.useMemo(() => {
      const computedInputs =
        evalContext && evalJsCode<TypedFormInputs<AddFormSubmissionFormData>>(inputsExpression, evalContext);
      return formInputsAsArray(computedInputs);
    }, [inputsExpression, evalContext]);
  };

type GetDefaultValuesArgs = {
  user: AuthUser;
  dependencies: Record<string, unknown>;
  defaultValuesExpression: string;
  jobsiteFormsOptions: JobsiteFormOptionElement[];
  contractorsOptions: SelectOptionElement[];
  availableJobsiteWorkerOptions: AvailableJobsiteWorkerOptions;
};

export const getDefaultValues = (args: GetDefaultValuesArgs): AddFormSubmissionFormData => {
  const {
    user,
    dependencies,
    defaultValuesExpression,
    jobsiteFormsOptions,
    contractorsOptions,
    availableJobsiteWorkerOptions,
  } = args;

  const evalContext =
    dependencies &&
    getEvalContext({
      user,
      jobsiteFormSubmission: undefined,
      dependencies,
      options: {
        jobsiteForms: jobsiteFormsOptions,
        contractors: contractorsOptions,
        availableJobsiteWorkers: availableJobsiteWorkerOptions,
      },
    });

  return evalContext && evalJsCode(defaultValuesExpression, evalContext);
};

type WorkersUpdateInput = Required<Pick<JobsiteCreateFormSubmissionInput, 'jobsiteWorkers'>>;
type JobsiteWorkerUpdateInput = WorkersUpdateInput['jobsiteWorkers'][number];

export const getJobsiteWorkersCreateInput = (args: {
  data: AddFormSubmissionFormData;
  dirtyFields: DeepMap<AddFormSubmissionFormData, true>;
  form: Form;
}): WorkersUpdateInput => {
  const { data, form } = args;
  const jobsiteWorkerFields = form.content.modal.jobsiteWorkerFields as string[];

  return {
    jobsiteWorkers: jobsiteWorkerFields?.flatMap((jwField): JobsiteWorkerUpdateInput[] => {
      const jwFieldValue = data?.[jwField];
      if (Array.isArray(jwFieldValue)) {
        return jwFieldValue
          ?.filter((jfsw) => jfsw.changeType)
          .map((jfsw) => ({
            changeType: jfsw.changeType as ChangeType,
            id: jfsw.id,
            jobsiteWorkerId: jfsw.jobsiteWorker.jobsiteWorkerId,
            associationType: jfsw.associationType,
            extraData: jfsw.extraData,
          }));
      }
      if (jwFieldValue) {
        const newValue = jwFieldValue as AvailableJobsiteWorkerOption;
        return [
          // add the new value
          {
            changeType: ChangeType.Created,
            id: newValue.id,
            jobsiteWorkerId: newValue.jobsiteWorker.jobsiteWorkerId,
            associationType: newValue.associationType,
          },
        ];
      }
      return undefined;
    }),
  };
};

export type GetJobsiteFormSubmissionCreateInputArgs = {
  user: AuthUser;
  dependencies: Record<string, unknown>;
  form: Form;
  data: AddFormSubmissionFormData;
  dirtyFields: DeepMap<AddFormSubmissionFormData, true>;
};

export const getJobsiteFormSubmissionCreateInput = ({
  user,
  dependencies,
  form,
  data,
  dirtyFields,
}: GetJobsiteFormSubmissionCreateInputArgs): JobsiteCreateFormSubmissionInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  const { updateInputs: updateInputsExpression, extraDataFields } = form.content.modal ?? {};

  /**
   * This function receives `date` and `time` as arguments and returns a date object composed based on the arguments.
   * The returned Date object will be calculated based on the `date` arguments as it follows:
   *  - if `date` is a field name, then the form field value will be used;
   *  - if `date` is a Date, then the passed value will be used.
   * @returns
   */
  const getDateTimeUpdateInput = (args: { date: string | Date | Moment; time: string; timeZone: string }): Date => {
    const { date, time, timeZone } = args;
    // get `updDate` only if a field name is specified through date parameter
    const updDate = typeof date === 'string' && !moment(date).isValid() ? getUpdateInputValue(date, true) : undefined;
    const updTime = getUpdateInputValue(time);
    return updDate || updTime
      ? getDateTime({ date: updDate ?? moment.utc(date), time: data[time] as string, timeZone, isUTC: true })
      : undefined;
  };
  const evalContext = getEvalContext({
    user,
    jobsiteFormSubmission: undefined,
    dependencies,
    edit: data,
    fn: { getDateTimeUpdateInput },
  });
  const customUpdateInputs = evalJsCode<JobsiteCreateFormSubmissionInput>(updateInputsExpression, evalContext);

  const extraDataInput = Object.fromEntries(
    (extraDataFields as string[]).map((field) => [field, getUpdateInputValue(field)]),
  );

  const jobsiteContractorUpdateInput = getUpdateInputValue('jobsiteContractorId', true);

  return ensureNonUndefinedFields<JobsiteCreateFormSubmissionInput>({
    extraData: extraDataInput,
    jobsiteFormId: getUpdateInputValue('jobsiteFormId', true),
    jobsiteContractors: jobsiteContractorUpdateInput && [
      {
        changeType: ChangeType.Created,
        jobsiteContractorId: jobsiteContractorUpdateInput,
      },
    ],
    ...customUpdateInputs,
    ...getJobsiteWorkersCreateInput({ data, dirtyFields, form }),
  });
};
