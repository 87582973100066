import { getFaIcon } from '@odin-labs/components';
import {
  faArrowLeft,
  faArrowRight,
  faArrowToBottom,
  faBriefcase,
  faPlus,
  faToolbox,
  faUser,
  faWrench,
  faFileExcel,
  faFileVideo,
  faFilePdf,
  faFileCode,
  faEnvelope,
  faSend,
  faTrash,
  faPencil,
  faConstruction,
} from '@fortawesome/pro-light-svg-icons';

export const ArrowToBottomIcon = getFaIcon({ icon: faArrowToBottom });
export const ArrowRightIcon = getFaIcon({ icon: faArrowRight });
export const PlusIcon = getFaIcon({ icon: faPlus });
export const BriefcaseIcon = getFaIcon({ icon: faBriefcase });
export const WrenchIcon = getFaIcon({ icon: faWrench });
export const BackIcon = getFaIcon({ icon: faArrowLeft });
export const FileExcelIcon = getFaIcon({ icon: faFileExcel });
export const FileVideoIcon = getFaIcon({ icon: faFileVideo });
export const FilePdfIcon = getFaIcon({ icon: faFilePdf });
export const FileCodeIcon = getFaIcon({ icon: faFileCode });
export const EnvelopeIcon = getFaIcon({ icon: faEnvelope });
export const SendIcon = getFaIcon({ icon: faSend });
export const TrashIcon = getFaIcon({ icon: faTrash });
export const ToolboxIcon = getFaIcon({ icon: faToolbox });
export const UserIcon = getFaIcon({ icon: faUser });
export const PencilIcon = getFaIcon({ icon: faPencil });
export const ConstructionIcon = getFaIcon({ icon: faConstruction });
